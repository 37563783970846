import React, {useEffect, useRef, useState} from "react";
import "./ProductCard.css"
import {LandingPageHelper} from "../../../Utilities/CmsHelpers";
import UserService from "../../../../services/UserService";
import {useAccount} from "../../../../context/AccountContext";
import {useAuth} from "../../../../context/AuthContext";
import {useNavigate} from "react-router-dom";

function ProductCard({product, productData, vertical}){
    const ref = useRef();

    const [isDesktopView, setIsDesktopView] = useState();


    useEffect(() => {
        const updateScreenWidth = () => {
            if(ref.current){
                setIsDesktopView(ref.current.parentElement.getBoundingClientRect().width >= 1014);
            }
        };

        window.addEventListener('resize', updateScreenWidth);

        updateScreenWidth()

        return () => {
            window.removeEventListener('resize', updateScreenWidth);
        };
    }, []);

    const infUsp = LandingPageHelper.getProgramPrevUSPData(productData);
    const usps = LandingPageHelper.getProgramUSPs(productData);
    const ribbon = LandingPageHelper.getProgramRibbon(productData);

    let account = useAccount();
    const  {setCheckLoggedIn} = useAuth()
    let navigate = useNavigate();
    const cardAction = () => {
        sessionStorage.setItem("selectedProgramId", JSON.stringify(product));
        account.clearSessionCoupon();
        if (UserService.isLoggedIn()) {
            setCheckLoggedIn(true);
            navigate("/PickProgram");
        } else {
            if (vertical) { //since only free products can be vertical
                UserService.doRegister(
                    process.env.REACT_APP_START_FOR_FREE_REDIRECT_URI,
                    account.language
                );
            } else {
                UserService.doRegister(
                    process.env.REACT_APP_START_FOR_NOTFREE_REDIRECT_URI,
                    account.language
                );
            }
        }
    };

    if(!(!!productData))
        return <></>
    else
    return <div ref={ref} className={`product-card-wrapper-hover ${vertical && isDesktopView ? "vertical" : ""}`} style={{order: (vertical && isDesktopView) ? 1 : "unset"}}>
        <div className={`product-card-wrapper`}>
            {!!ribbon?.text &&
                <span className={"product-card-ribbon"} style={{backgroundColor: ribbon?.bgColor}}>
                    {!!ribbon?.icon &&
                        <img src={ribbon.icon} alt={"product ribbon icon"}/>
                    }
                    {ribbon.text}
                </span>
            }
            <div className={"product-card-upper"}>
                <span className={"product-card-title"} style={{backgroundColor: LandingPageHelper.getProgramColor(productData)}}>
                    {LandingPageHelper.getProgramTitle(productData)}
                </span>
                <div className={"product-card-usps"}>
                    <ul>
                        {!!infUsp &&
                            <li>
                                Όλο το περιεχόμενο του <span style={{color: infUsp.colorCode}}>{infUsp.Target_Product_Family}</span> προγράμματος
                            </li>
                        }
                        {!!usps &&
                            usps.map((usp, index) => {
                                return <li key={index}>{usp?.value}</li>
                            })
                        }
                    </ul>
                </div>
            </div>
            <div className={"product-card-lower"}>
                {((!!productData?.price && !!productData?.afterPrice) && !vertical) &&
                    <div className={"product-card-price"}>
                        <span className={"product-card-price-tag"}>{productData.price}</span> <span className={"product-card-price-frequency"}>{productData.afterPrice}</span>
                    </div>
                }
                <button className={"product-card-cta"} onClick={cardAction}>
                    {productData?.ctaText}
                </button>
            </div>
        </div>
    </div>
}

export default ProductCard;