import React, {useEffect, useState} from "react";
import FlowContainer from "../FlowContainer";
import CmsHelper, {PickProgramPageHelper} from "../../../Utilities/CmsHelpers";
import {useCms} from "../../../../context/CmsContext";
import GoogleAnalytics from "../../../../analytics/GoogleAnalytics";
import AwsApiService from "../../../../services/AwsApiService";
import {useAccount} from "../../../../context/AccountContext";
import {useAuth} from "../../../../context/AuthContext";
import AccountsService from "../../../../services/AccountsService";
import {useNavigate} from "react-router-dom";
import GenericError from "../../../Utilities/GenericError";
import PopUp from "../../../Utilities/PopUp";
import {Button, Link} from "@mui/material";

export default function MigrationFlow({packages, couponExists, handleNextStep,
                                          contestSession,
                                          promoTargetDisplayName, promoRedirectURL,
                                          flow,
                                          cancellationFlow,
                                      token}) {
    const account = useAccount();
    const {pickProgramData} = useCms();

    const { setCheckLoggedIn, authData } = useAuth();

    const navigate = useNavigate();

    const [products, setProducts] = useState([])

    const [successfulCouponUse, setSuccesfulCouponUse] = useState(false);
    const [failedCouponUse, setFailedCouponUse] = useState(false);
    const [unableToUpgrade, setUnableToUpgrade] = useState(false);
    const [sameProductVerification, setSameProductVerification] = useState(false);
    const [onVerifyCouponRemoval, setOnVerifyCouponRemoval] = useState(()=>{})

    useEffect(()=>{
        setLoading(true);
        let products;

        if(!!couponExists || !!token){
            products = packages ?? []
        }else{
            if(packages.displayName !== "Lite" && contestSession){
                handleNextStep();
                navigate("/CongratsPage")
                return;
            }
            products = [...packages.upgrades, ...(cancellationFlow ? [] : packages.downgrades)];
            products = products.filter(product => product.displayName !== "Lite"); //removes lite products from upgrade.

            if (!!promoTargetDisplayName) {
                const targetDisplayNames = JSON.parse(promoTargetDisplayName);
                products = products.filter(item1 => targetDisplayNames.some(item2 => item1.displayName === item2))
                if(products.length === 0){
                    window.open(promoRedirectURL, "_self")
                    return;
                }
            }
        }

        if(products.length === 0){
            setUnableToUpgrade(true);
            account.clearSessionCoupon();
            setLoading(false);
            return;
        }

        if(!!couponExists &&
        products.length === 1 &&
        products[0].id === packages.productId &&
        products[0].pricePoint === packages.pricePoint){ //same product just use coupon
            const couponCodes = { couponCodes: [couponExists] };
            setCheckLoggedIn(true);
            AccountsService.addCoupon(couponCodes)
                .then((response) => {
                    //added coupon to svod acount
                    setSuccesfulCouponUse(true);
                })
                .catch((error) => {
                    //failed to add coupon to svod account
                    setFailedCouponUse(true);
                }).finally(()=>{
                    setLoading(false);
                })
            return;
        }else{
            setLoading(false);
        }

        setProducts(products);

    }, [packages, couponExists, contestSession, promoTargetDisplayName, handleNextStep, promoRedirectURL]) //eslint-disable-line react-hooks/exhaustive-deps

    const [loading, setLoading] = useState(false);
    const onNext = (product, setCardInUse, setError) =>{
        setLoading(true);

        let request = {
            productId: product.productId,
            newProductId: product.productId,
            pricePoint: product.pricePoint,
        };

        if (!!couponExists) {
            request = {
                ...request,
                couponCodes: [`${couponExists}`],
            };
        }

        account.setChosenProgramName(
            products.find((obj) => obj.productId === product.productId).name
        );
        GoogleAnalytics.onSelectPackage();
        AwsApiService.onPickProgram();


        sessionStorage.removeItem("selectedProgramId");

        const migrate = async (request) =>{
            setLoading(true)

            AccountsService.upgradeSubscription({...request, flow: flow})
                .then((response) => {
                    const newProduct = response.data.subscription.product
                    account.setupAnalyticsProductConvertion({
                        pricePoint: newProduct.product_price_point_handle,
                        productName: newProduct.name,
                        pricePointId: newProduct.product_price_point_id,
                    })

                    if (sessionStorage.getItem("discount-coupon")) {
                        sessionStorage.removeItem("discount-coupon");
                    }
                    if(request.couponCodes){
                        setSuccesfulCouponUse(true);
                        setFailedCouponUse(false);
                    }else{
                        handleNextStep(true);
                        navigate("/CongratsPage");
                    }
                })
                .catch((err) => {
                    account.setProductId(request.productId);
                    account.setPricePoint(request.pricePoint);
                    account.setFlow(flow);
                    account.setSkipFilters(packages.displayName === "Lite" && couponExists);
                    if(request.token){
                        sessionStorage.setItem("save_desk_token", request.token)
                    }
                    if ([402, 300].includes(err.response.data.code)) {
                        account.setChargifyAccess(true);

                        handleNextStep();
                        navigate("/ChargifyForm");
                    } else if (err.response.data.code === 403) {
                        setCheckLoggedIn(false);
                        sessionStorage.setItem("cardValidationFlow", "upgrade");
                        sessionStorage.setItem("cardValidationToken", err.response.data.token);
                        sessionStorage.setItem("subscriptionRequest", JSON.stringify({...request, flow: flow}));
                        navigate("/PhoneVerification");
                    } else if (err.response.data.code === 404) {
                        setCardInUse(true, migrate.bind(null, { ...request, noTrialAccepted: true }), ()=>{
                            handleNextStep()
                            AccountsService.deletePaymentProfile({paymentId: err.response.data.paymentProfileId})
                                .then(response => {

                                })
                                .catch(error => {
                                    console.log("Error deleting payment method")
                                })
                        });
                    }else if (err.response.data.code === 425) {
                        setSameProductVerification(true);
                        setOnVerifyCouponRemoval(()=> {
                            setLoading(true)
                            setSameProductVerification(false);
                            migrate({...request, verified: true})
                        })
                    } else {
                        setError(true);
                    }
                })
                .finally(()=>{
                    setLoading(false)
                });
        }

        if(!!couponExists &&
            !!authData.subscriptionId &&
            product.productId === packages.productId &&
            product.pricePoint === packages.pricePoint){ //same product just use coupon
            const couponCodes = { couponCodes: [couponExists] };
            setCheckLoggedIn(true);
            AccountsService.addCoupon(couponCodes)
                .then((response) => {
                    //added coupon to svod acount
                    setSuccesfulCouponUse(true);
                })
                .catch((error) => {
                    //failed to add coupon to svod account
                    setFailedCouponUse(true);
                }).finally(()=>{
                setLoading(false);
            })
            return;
        }
        migrate({...request, ...(token !== null ? { token: token } : {})});
    }

    return <>
        <FlowContainer packages={products}
                       loading={loading}
                       update={true}
                       currentProgram={!!packages?.displayName && !!packages?.productId ? {displayName: packages.displayName, productId: packages.productId} : undefined}
                       cancellationFlow={cancellationFlow}
                       couponExists={!!couponExists}
                       contestState={contestSession}
                       cmsData={PickProgramPageHelper.getPrograms(pickProgramData, cancellationFlow ? CmsHelper.CANCELLATION_MIGRATE : CmsHelper.UPGRADE_PROGRAM)}
                       onNext={onNext}
        />
        <PopUp
            openPopUp={failedCouponUse}
            setOpenPopUp={setFailedCouponUse}
            maxWidth="md"
            showCloseButton={false}
            outlined={false}
            error={true}
        >
            <GenericError
                setClose={setFailedCouponUse}
                success={true}
                mainText={"Αποτυχία εξαργύρωσης!"}
                secondaryText={"Ο κωδικός δεν είναι έγκυρος"}
                buttonText={"Συνέχεια"}
                executeFunction={() => navigate("/Account")}
                showIcon={false}
            />
        </PopUp>
        <PopUp
            openPopUp={successfulCouponUse}
            setOpenPopUp={setSuccesfulCouponUse}
            maxWidth="md"
            showCloseButton={false}
            outlined={false}
            error={false}
            closeWhenClickedOutside={false}
        >
            <GenericError
                setClose={setSuccesfulCouponUse}
                success={true}
                mainText={"Επιτυχής εξαργύρωση κωδικού!"}
                buttonText={"Συνέχεια"}
                showIcon={false}
                executeFunction={() => {
                    handleNextStep(true);
                    sessionStorage.setItem("coupon-success", true);
                    navigate("/CongratsPage");
                }}
            />
        </PopUp>
        <PopUp
            openPopUp={unableToUpgrade}
            setOpenPopUp={(can)=>{setUnableToUpgrade(!can)}}
            maxWidth="md"
            showCloseButton={false}
            outlined={false}
            error={true}
            closeWhenClickedOutside={false}>
            <GenericError
                setClose={setUnableToUpgrade}
                buttonText={"Επιστροφή"}
                executeFunction={function () {
                    window.open(`${process.env.REACT_APP_CONGRATS_BUTTON_LINK}`, "_self");
                }}
                mainText={"Δεν υπάρχει προσφορά συμβατή με το πακέτο σου αυτή τη στιγμή."}
                showIcon={false}>
                <span className={"pick-program-fallback-modal"}>Για περισσότερες πληροφορίες επισκέψου το <Link href={"https://help.antennaplus.gr/"} target={"_blank"}>Κέντρο Βοήθειας</Link>.</span>
            </GenericError>
        </PopUp>
        <PopUp
            openPopUp={sameProductVerification}
            setOpenPopUp={setSameProductVerification}
            maxWidth="md"
            showCloseButton={false}
            outlined={false}
            error={true}
        >
            <GenericError
                setClose={setSameProductVerification}
                success={true}
                mainText={"Με την προσθήκη νέου κωδικού προσφοράς, τυχόν υπόλοιπο δωρεάν χρήσης της υπηρεσίας εξαντλείται αυτόματα."}
                secondaryText={"Σε περίπτωση που αποδέχεσαι την άμεση ενεργοποίηση της νέας προσφοράς πάτησε Συνέχεια, διαφορετικά πάτησε Επιστροφή και μπορείς να την ενεργοποιήσεις όταν λήξει η ήδη υπάρχουσα."}
                showIcon={false}
                hideButtons={true}
            />
            <div id="card-in-use-actions">
                <Button
                    variant="text"
                    color="primary"
                    className="grey"
                    onClick={() => {
                        sessionStorage.removeItem("discount-coupon")
                        navigate("/Account")
                    }}
                >
                    Επιστροφή
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onVerifyCouponRemoval}
                >
                    Συνέχεια
                </Button>
            </div>
        </PopUp>
    </>
}