import React, {useEffect, useState} from "react";
import FlowContainer from "../FlowContainer";
import CmsHelper, {PickProgramPageHelper} from "../../../Utilities/CmsHelpers";
import {useCms} from "../../../../context/CmsContext";
import GoogleAnalytics from "../../../../analytics/GoogleAnalytics";
import AwsApiService from "../../../../services/AwsApiService";
import {useAccount} from "../../../../context/AccountContext";
import {useAuth} from "../../../../context/AuthContext";
import AccountsService from "../../../../services/AccountsService";
import UserService from "../../../../services/UserService";
import {useNavigate} from "react-router-dom";
import GenericError from "../../../Utilities/GenericError";
import PopUp from "../../../Utilities/PopUp";

export default function RegistrationFlow({packages, couponExists, handleNextStep,
                                             contestSession, promoTargetDisplayName}) {
    const account = useAccount();
    const {pickProgramData} = useCms();

    const { setCheckLoggedIn } = useAuth();

    const navigate = useNavigate();

    const [products, setProducts] = useState([])

    const [successfulCouponUse, setSuccesfulCouponUse] = useState(false);

    useEffect(()=>{
        let products = [...packages];

        if (!(!!couponExists) && !!promoTargetDisplayName) {
            const targetDisplayNames = JSON.parse(promoTargetDisplayName);
            products = products.filter(item1 => targetDisplayNames.some(item2 => item1.displayName === item2))
        }

        if(contestSession){
            products = products.filter(product => product.displayName !== "Lite")
        }

        setProducts(products);

    }, [packages, couponExists, contestSession, promoTargetDisplayName])

    const [loading, setLoading] = useState(false);
    const onNext = (product, setCardInUse, setError) =>{

        let request = {
            productId: product.productId,
            newProductId: product.productId,
            pricePoint: product.pricePoint,
        };

        if (!!couponExists) {
            request = {
                ...request,
                couponCodes: [`${couponExists}`],
            };
        }

        account.setChosenProgramName(
            packages.find((obj) => obj.productId === product.productId).name
        );
        GoogleAnalytics.onSelectPackage();
        AwsApiService.onPickProgram();


        sessionStorage.removeItem("selectedProgramId");

        const subscribe = async (request) =>{
            setLoading(true)

            AccountsService.subscribe(request)
                .then((response) => {
                    const newProduct = response.data.subscription.product
                    account.setupAnalyticsProductConvertion({
                        pricePoint: newProduct.product_price_point_handle,
                        productName: newProduct.name,
                        pricePointId: newProduct.product_price_point_id,
                    })

                    sessionStorage.setItem("kc_resolve_token", response.data.token);

                    handleNextStep(true);
                    if(couponExists){
                        setSuccesfulCouponUse(true)
                    }else{
                        if (UserService.isSubscriptionAction()) {
                            navigate("/CongratsPage");
                        } else {
                            // setLoading(false);
                            navigate("/CongratsPage");
                        }
                    }
                })
                .catch((err) => {
                    // setLoading(false);
                    account.setProductId(request.productId);
                    account.setPricePoint(request.pricePoint);
                    if ([402, 300].includes(err.response.data.code)) {
                        account.setChargifyAccess(true);

                        handleNextStep();
                        navigate("/ChargifyForm");
                    } else if ([401, 422].includes(err.response.status)) {
                        UserService.clearActionToken();
                        // console.debug("Retrying authorization")
                        try{
                            UserService.doLogin(window.location.origin + "/PickProgram",account.language);
                        }catch (e){
                            // console.debug("Error slow connection")
                        }
                    } else if (err.response.data.code === 403) {
                        setCheckLoggedIn(false);
                        sessionStorage.setItem(
                            "cardValidationToken",
                            err.response.data.token
                        );
                        sessionStorage.setItem(
                            "subscriptionRequest",
                            JSON.stringify(request)
                        );
                        navigate("/PhoneVerification");
                    } else if (err.response.data.code === 404) {
                        setCardInUse(true, subscribe.bind(null, { ...request, noTrialAccepted: true }), ()=>{
                            handleNextStep();
                            AccountsService.deletePaymentProfile({paymentId: err.response.data.paymentProfileId})
                                .then(response => {

                                })
                                .catch(error => {
                                    console.log("Error deleting payment method")
                                })
                        });
                    } else {
                        setError(true);
                    }
                })
                .finally(()=>{
                    setLoading(false)
                });
        }

        subscribe(request);
    }

    return <>
        <FlowContainer packages={products}
                       loading={loading}
                       update={false}
                       couponExists={!!couponExists}
                       contestState={contestSession}
                       cmsData={PickProgramPageHelper.getPrograms(pickProgramData, CmsHelper.REGISTRATION)}
                       onNext={onNext}
        />
        <PopUp
            openPopUp={successfulCouponUse}
            setOpenPopUp={setSuccesfulCouponUse}
            maxWidth="md"
            showCloseButton={false}
            outlined={false}
            error={false}
            closeWhenClickedOutside={false}
        >
            <GenericError
                setClose={setSuccesfulCouponUse}
                success={true}
                mainText={"Επιτυχής εξαργύρωση κωδικού!"}
                buttonText={"Συνέχεια"}
                showIcon={false}
                executeFunction={() => {
                    handleNextStep(true);
                    sessionStorage.setItem("coupon-success", true);
                    navigate("/CongratsPage");
                }}
            />
        </PopUp>
    </>
}