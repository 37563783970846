export const formatDate = (dateString) => {
    if(dateString){
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }else{
        return '';
    }
};

export const gradientGetter = (product)=> {
    if(product){
        if (product.startsWith(Products.ULTIMATE)) {
            return "account-village"
        } else if (product.startsWith(Products.SPORTS)) {
            return "account-valuepack"
        } else if (product.startsWith(Products.FAMILY)) {
            return "account-plus"
        }else if (product.startsWith(Products.VILLAGE)) {
            return "account-village"
        }else if (product.startsWith(Products.VALUEPACK)) {
            return "account-valuepack"
        }else if (product.startsWith(Products.FREE)) {
            return "account-free"
        }else if (product.startsWith(Products.ANT1PLUS)) {
            return "account-plus"
        }
    }
    return "account-free"
}

export const Products = Object.freeze({
    FREE: "Free",
    ULTIMATE: "Family & Sports Ultimate",
    SPORTS: "Family & Sports",
    FAMILY: "Family",
    VILLAGE: "ANT1+ Village",
    VALUEPACK: "ANT1+ Value Pack",
    ANT1PLUS: "ANT1+"
})