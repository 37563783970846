import React, {
  useState,
  useRef,
  useEffect,
} from "react";
import "./Header.css";
import "../GlobalStyles.css";
import {
  AppBar,
  Avatar,
  Button,
  Popper,
  Toolbar,
  Paper,
  ClickAwayListener,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import UserService from "../../services/UserService";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GoogleAnalytics from "../../analytics/GoogleAnalytics";
import AwsApiService from "../../services/AwsApiService";
import { isDesktop } from "react-device-detect";
import Contest from "../Contest/Contest";
import {styled} from "@mui/system";
import {useLoading} from "../../context/LoadingContext";
import {useAuth} from "../../context/AuthContext";
import {useAccount} from "../../context/AccountContext";
import {Products} from "../Account/utils";

const CustomPaper = styled(Paper)(({ theme }) => ({
  maxWidth: 400,
  overflow: "auto",
  borderRadius: "5px 0px 5px 5px",
  backgroundColor: "transparent",
  boxShadow: "0px 2px 6px rgba(255, 255, 255, 0.25)",
}));

const CustomPopper = styled(Popper)(({ theme }) => ({
  zIndex: 9999,
  marginTop: "10px !important",
  '&[x-placement*="bottom"] $arrow': {
    top: 0,
    left: "83px !important",
    marginTop: "-0.9em",
    width: "3em",
    height: "1em",
    "&::before": {
      borderWidth: "0 1em 1em 1em",
      borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
    },
  },
}));

const CustomSpan = styled("span")(({ theme }) => ({
  // position: "absolute",
  fontSize: 7,
  width: "3em",
  height: "3em",
  "&::before": {
    content: '""',
    margin: "auto",
    display: "block",
    width: 0,
    height: 0,
    borderStyle: "solid",
    borderWidth: "0 1em 1em 1em",
    borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
    top: 0,
    left: "83px !important",
    marginTop: "-0.9em",
  },
}));

function Header() {
  const {login, authData, setCheckLoggedIn, loggedIn} = useAuth();


  const { isLoading } = useLoading();


  const account = useAccount();
  let navigate = useNavigate();
  // const classes = useStyles(theme);

  const ref = useRef();

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [openProfilePopper, setOpenProfilePopper] = useState(false);
  const [profileAnchor, setProfileAnchor] = useState(null);
  const [arrowRef, setArrowRef] = useState(null);
  const location = useLocation();

  // const handleClick = (event) => {
  //   if (open) {
  //     setOpen(false);
  //   } else {
  //     setOpen(true);
  //   }
  // };

  // const onLanguageClick = (value) => {
  //   account.setLanguage(value);
  //   i18next.changeLanguage(value);
  //   setOpen(false);
  // };

  const onLogoClick = (e) => {
    e.preventDefault();
    window.open(`${process.env.REACT_APP_LOGO_URI}`, "_self");
  };

  const onAvatarClick = (e) => {
    e.preventDefault();
    setCheckLoggedIn(false);
    setProfileAnchor(e.currentTarget);
    setOpenProfilePopper((previousOpen) => !previousOpen);
  };

  const onSubscriptionActionAvatarClick = (e) => {
    e.preventDefault();
    setCheckLoggedIn(true);
    navigate("/PickProgram");
  };

  const canBeOpen = openProfilePopper && Boolean(profileAnchor);
  const id = canBeOpen ? "transition-profile-popper" : undefined;

  const enterAccount = (e) => {
    e.preventDefault();
    GoogleAnalytics.onLogIn();
    AwsApiService.onLogin();
    const redirect_uri = isDesktop
      ? process.env.REACT_APP_LOGIN_REDIRECT_URI_DESKTOP
      : process.env.REACT_APP_LOGIN_REDIRECT_URI_MOBILE;
    // console.debug("Retrying authorization")
    try{
      login(redirect_uri,account.language);
    }catch (e){
      // console.debug("Error slow connection")
    }
  };

  const registerNewAccount = (e) => {
    e.preventDefault();
    // GoogleAnalytics.onLogIn();
    // AwsApiService.onLogin();
    const redirect_uri = isDesktop
      ? process.env.REACT_APP_LOGIN_REDIRECT_URI_DESKTOP
      : process.env.REACT_APP_LOGIN_REDIRECT_URI_MOBILE;
    UserService.doRegister(redirect_uri, account.language);
  };

  const openProfile = (e) => {
    account.setMigrateFromCancellation(false);
    navigate("/Account");
  };

  const onLogOut = (e) => {
    e.preventDefault();
    navigate("/");
    UserService.doLogout();
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the language options are open and the clicked target is not within them,
      // then close the options
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open]);


  useEffect(() => {
    //every time we navigate to another page close profile popper
    setOpenProfilePopper(false);
    // console.log(location.pathname);
  }, [location]);

  const iconGetter = ()=> {
    let product= authData.productName;
    if(product) {
      if (product.startsWith(Products.ULTIMATE)) {
        return "images/header/headerIconUnlimited.png"
      } else if (product.startsWith(Products.SPORTS)) {
        return "images/header/headerIconSports.png"
      } else if (product.startsWith(Products.FREE)) {
        return "images/header/headerIconFree.svg"
      } else if (product.startsWith(Products.FAMILY)) {
        return "images/header/headerIconPremium.png"
      }else if (product.startsWith(Products.VALUEPACK)) {
        return "images/header/headerIconValue.svg"
      } else if (product.startsWith(Products.VILLAGE)) {
        return "images/header/headerIconVillage.svg"
      } else if (product.startsWith(Products.ANT1PLUS)) {
        return "images/header/headerIconPlus.svg"
      } else {
        return "images/header/headerIconEmpty.png"
      }
    }else {
      return "images/header/headerIconEmpty.png"
    }
  }

  return (
    <div
      id="header-wrapper"
      className={account.inHomepage ? "homepage" : ""}
    >
      {account.inHomepage &&
          <Contest />
      }
      <AppBar
        position="static"
        color="transparent"
        id="page-header"
        className={account.inHomepage ? "transparent" : "dark"}
      >
        <Toolbar className="toolbar">
          <img
            src="images/ant1Logo.png"
            alt="ant1 logo"
            className="ant1-logo"
            onClick={onLogoClick}
          />
          <div className="actions">
            {/* <div id="language-dropdown" ref={ref}>
            <Button
              variant="text"
              className="white"
              onClick={handleClick}
              id="language-button"
            >
              {account.language === "gr" ? "ΕΛ" : "EN"} <LanguageIcon />
            </Button>
            <div className={`languages ${!open ? "hidden" : ""}`}>
              <div
                className={`language-option ${
                  account.language === "en" ? "hidden" : ""
                }`}
                onClick={() => onLanguageClick("en")}
              >
                EN
              </div>
              <div
                className={`language-option ${
                  account.language === "gr" ? "hidden" : ""
                }`}
                onClick={() => onLanguageClick("gr")}
              >
                ΕΛ
              </div>
            </div>
          </div> */}
            {(isLoading() || loggedIn === undefined) ? (
                <></>
            ):(
                <>
                  {loggedIn ? (
                      <>
                        {UserService.isSubscriptionAction() ? (
                            <>
                              <Avatar
                                  alt="account profile picture"
                                  src="images/header/headerIconEmpty.png"
                                  id="user-avatar"
                                  onClick={onSubscriptionActionAvatarClick}
                                  aria-describedby={id}
                              />
                            </>
                        ): (
                            <>
                              <ClickAwayListener onClickAway={()=>{setOpenProfilePopper(false);}}>
                                <div>
                                  <img className={"avatarHero"}
                                       src={iconGetter()}
                                       alt={"account"} onClick={onAvatarClick}
                                       aria-describedby={id}
                                       id="user-avatar"/>
                                  {/*<Avatar*/}
                                  {/*    alt="account profile picture"*/}
                                  {/*    src={`images/header/headerIconFree.svg`}*/}
                                  {/*    id="user-avatar"*/}
                                  {/*    onClick={onAvatarClick}*/}
                                  {/*    aria-describedby={id}*/}
                                  {/*/>*/}
                                  <CustomPopper
                                      id={id}
                                      open={openProfilePopper}
                                      anchorEl={profileAnchor}
                                      // transition
                                      // className={classes.popper}
                                      modifiers={[{
                                          name: 'arrow',
                                          enabled: true,
                                          options: {
                                            element: arrowRef,
                                          },
                                        }]}
                                  >
                                    <CustomSpan ref={setArrowRef} />
                                    <CustomPaper>
                                      <div className="profile-popper">
                                        <div className="profile-wrapper">
                                          <div className="profile-email">
                                            {authData.email}
                                          </div>
                                          <div className="profile-link" onClick={openProfile}>
                                            {t("headerGoToAccount")}
                                          </div>
                                        </div>
                                        <div className="logout" onClick={onLogOut}>
                                          {t("headerSignOut")}
                                        </div>
                                      </div>
                                    </CustomPaper>
                                  </CustomPopper>
                                </div>
                              </ClickAwayListener>
                            </>
                        )}
                      </>
                  ) : (
                      <div id="action-buttons">
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={registerNewAccount}
                            id="register-button"
                        >
                          Εγγραφή
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={enterAccount}
                            id="sign-in-button"
                        >
                          {t("headerSignIn")}
                        </Button>
                      </div>
                  )}
                </>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Header;
