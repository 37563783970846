import React, {useEffect, useState} from "react";
import "./Banner.css";
import {Button} from "@mui/material";
import UserService from "../../services/UserService";
import GoogleAnalytics from "../../analytics/GoogleAnalytics";
import AwsApiService from "../../services/AwsApiService";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import EnterCoupon from "../EnterCoupon/EnterCoupon";
import CmsHelper, {LandingPageHelper} from "../Utilities/CmsHelpers";
import PopUp from "../Utilities/PopUp";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
// import contest from "../Contest/Contest";
import CheckBox from "../Account/components/CheckBox/CheckBox";
import Markdown from "react-markdown";
import {useCms} from "../../context/CmsContext";
import {useAuth} from "../../context/AuthContext";
import {useAccount} from "../../context/AccountContext";

function Banner({avod = false,
                    hasSubscription = false,
                    productsRef,
                    productName = "",
                    goToProducts = false,
                    bannerData,
                    isContest}) {

    const account = useAccount();

    const { setCheckLoggedIn,loggedIn  } = useAuth();


    const {renderingType} = useCms();

    const [termsPopUp, setTermsPopUp] = useState(false);

    useEffect(() => {
        if (goToProducts) {
            onGoToProductsSection();
        }
    }, [goToProducts]); //eslint-disable-line react-hooks/exhaustive-deps

    const onRegister = (redirectURI) => {
        GoogleAnalytics.onRegister();
        AwsApiService.onRegister();
        UserService.doRegister(redirectURI, account.language);
    };

    const onTryFreeTrial = () => {
        window.open(`${process.env.REACT_APP_HOMEPAGE_AVOD_BUTTON_LINK}`, "_self");
    };

    const onStart = () => {
        setCheckLoggedIn(false);
        window.open(`${process.env.REACT_APP_CONGRATS_BUTTON_LINK}`, "_self");
    };

    const onGoToProductsSection = () => {
        if (productsRef) {
            const element = productsRef.current;
            element.scrollIntoView({
                behavior: "smooth",
            });
        }
    };

    const handleContestSubmit = ()=>{
        if(termsAndConditions){
            setCheckLoggedIn(false);
            sessionStorage.setItem("contestSession", bannerData.identifier)
            sessionStorage.setItem("contestRedirectURL", bannerData.redirectURL)
            if (!loggedIn) {
                onRegister(process.env.REACT_APP_PICKPROGRAM_URL);
            } else {
                window.open(process.env.REACT_APP_PICKPROGRAM_URL, "_self")
            }
        }else{
            setTermsAndConditionsError(true);
        }
    }

    const price = isContest ? undefined : LandingPageHelper.getBannerPricing(bannerData.price, productName);
    const onHeroButtonClick = (e) => {
        e.preventDefault();
        if (bannerData.redirectURL && bannerData.target_dsp_n?.data) {
            setCheckLoggedIn(false);
            sessionStorage.setItem("promoRedirectURL", bannerData.redirectURL)
            sessionStorage.setItem("promoTargetDisplayName", JSON.stringify(bannerData.target_dsp_n?.data.map(names => names.attributes.value)))
            if (!loggedIn) {
                onRegister(process.env.REACT_APP_PICKPROGRAM_URL);
            } else {
                window.open(process.env.REACT_APP_PICKPROGRAM_URL, "_self")
            }
        } else {
            if (!loggedIn) {
                onRegister(process.env.REACT_APP_REGISTER_REDIRECT_URI);
            } else if (avod) {
                onTryFreeTrial();
            } else if (hasSubscription) {
                onStart();
            }
        }
    };
    const [isBannerLoading, setIsBannerLoading] = useState(true)

    const [termsAndConditions, setTermsAndConditions] = useState(false);
    const [termsAndConditionsError, setTermsAndConditionsError] = useState(false);

    return (<div id="banner" style={{  display: isBannerLoading ? "contents":"",
                     backgroundImage: `${(bannerData && bannerData.images) ? 
                         `url(${CmsHelper.getImagePath(CmsHelper.getImageData(bannerData.images, renderingType))})` : ""}`}}>
        <img alt={""} src={CmsHelper.getImagePath(CmsHelper.getImageData(bannerData.images, renderingType))} style={{ display: "none"}} onLoad={()=>{setIsBannerLoading(false)}} />
            {(bannerData && bannerData.id) &&
                <>
                  <div id="banner-wrapper">
                    <div id="main-section" className={`${isContest ? "bannerContest": ""}`}>
                      <div className={`info ${isContest ? "bannerContest": ""}`}>
                        <h1 className={`${isContest ? "bannerContest": ""}`}>{bannerData.title}</h1>
                        {CmsHelper.getDetails(bannerData.details).map((item, index) => {
                          return <p key={index}>{item}</p>
                        })}
                      </div>
                      <div className="price-and-actions">
                          {!isContest &&
                              <p>
                                  <span>{price?.priceDescr}</span> {price?.price}
                              </p>
                          }

                          <div className="buttons">
                              <Button variant="contained"
                                      color="primary"
                                      onClick={isContest ? handleContestSubmit : onHeroButtonClick}
                                      id="banner-register-button">
                                {isContest ? "Δήλωσε Συμμετοχή":price?.primary_btn}
                              </Button>
                              {(!isContest && price?.secondary_btn) &&
                                  <Button variant="outlined"
                                          color="primary"
                                          onClick={onGoToProductsSection}
                                          id="check-products"
                                          endIcon={<ArrowDownwardIcon id="arrow-icon"/>}>
                                    {price?.secondary_btn}
                                  </Button>}
                          </div>

                          {isContest ?
                              <CheckBox disabled={false}
                                        action={()=>{setTermsAndConditions((prevState)=>!prevState);setTermsAndConditionsError(false)}}
                                        checked={termsAndConditions}
                                        text={<div className={`terms-and-conditions ${termsAndConditionsError ? "contestError": ""}`}>
                                            <span>Έχω διαβάσει και αποδέχομαι τους{" "}</span>
                                            <Button variant="text" onClick={() => setTermsPopUp(true)}>
                                                όρους του διαγωνισμού
                                            </Button>
                                        </div>}/>
                              :
                              <div className="terms-and-conditions">
                                  *Ισχύουν όροι και προϋποθέσεις.{" "}
                                  <Button variant="text" onClick={() => setTermsPopUp(true)}>
                                    Διάβασε εδώ
                                  </Button>
                              </div>
                          }
                      </div>
                    </div>
                  </div>
                    {!isContest &&
                      <div id="coupon-wrapper">
                        <EnterCoupon/>
                      </div>
                    }
                  <PopUp openPopUp={termsPopUp}
                         setOpenPopUp={setTermsPopUp}
                         maxWidth="md"
                         showCloseButton={true}
                         title={"Όροι και προϋποθέσεις"}>
                      {isContest ?
                          <div className="terms-and-conditions-wrapper">
                              <Markdown>{bannerData.termsAndConditions}</Markdown>
                          </div>
                          :<TermsAndConditions/>
                      }
                  </PopUp>
                </>}
    </div>);
}

export default Banner;
